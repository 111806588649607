import React, { Component } from 'react';
import './styles.scss';
import ViewTemplate from '../../components/ViewTemplate';

class Error404View extends Component {
    render = () => {
        return (
            <ViewTemplate id='error-404-view'>
                
            </ViewTemplate>
        )
    }
}

export default Error404View;