import React, { Component } from 'react';

class ViewTemplate extends Component {
    componentDidMount = () => {
        window.scroll({ top: 0 });
    }
    
    render = () => {
        return (
            <div id={this.props.id}>
                {this.props.children}
            </div>
        )
    }
}

export default ViewTemplate;