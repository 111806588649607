import React, { Component } from 'react';
import './styles.scss';
import ViewTemplate from '../../components/ViewTemplate';
import Footer from '../../components/Footer';
import ReactGA from 'react-ga';

class RandomizeMeView extends Component {
    downloadBadge_Click = (os) => {
        return () => {
            ReactGA.event({
                category: `${os}_download_badge`,
                action: 'click',
            });
        }
    }

    render = () => {
        return (
            <ViewTemplate id='randomize-me-view'>
                <div className='header'>
                    <div className='brand'>
                        <img src='/assets/randomize-me/logo.png' />
                        <h2>RandomizeMe</h2>
                    </div>
                    <h1>Randomness is a lifestyle.</h1>
                    <div className='badges'>
                        <a target='_blank' href='https://apps.apple.com/app/randomizeme/id1475005309'
                            onClick={this.downloadBadge_Click('ios')}>
                            <img src='/assets/app-store-badge.svg' />
                        </a>
                        <a target='_blank' href='https://play.google.com/store/apps/details?id=io.mfprint.randomizeme'
                            onClick={this.downloadBadge_Click('android')}>
                            <img src='/assets/google-play-badge.png' />
                        </a>
                    </div>
                    <img className='preview' src='/assets/randomize-me/list-ios.png' />
                </div>
                <Footer />
            </ViewTemplate>
        )
    }
}

export default RandomizeMeView;