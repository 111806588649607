// Footer Component

import React, { Component } from 'react';
import './styles.scss';

import { NavHashLink as Link } from 'react-router-hash-link';

export default class Footer extends Component {
    render() {
        return (
            <div id='footer'>
                <div className='footer-columns'>
                    <Contact />
                    <LinksOfInterest />
                    <SiteMap />
                </div>
                <div className='no-bytes'>
                    /* No bytes were harmed in the making of this website! */
                </div>
            </div>
        )
    }
}

const Contact = props => {
    return (
        <div className='footer-col'>
            <h2>Contact</h2>
            <div className='contact-list'>
                {/* <ContactItem id='facebook' text='/martnfigurate'
                    link='https://facebook.com/martnfigurate' /> */}
                <ContactItem id='twitter' text='@martnfigurate'
                    link='https://twitter.com/martnfigurate' />
                <ContactItem id='email' text='martin@mfprint.io'
                    link='mailto:martin@mfprint.io' />
                <ContactItem id='github' text='/mfprint'
                    link='https://github.com/mfprint' />
            </div>
        </div>
    )
}

const ContactItem = props => {
    return (
        <div className='contact-item'>
            <a href={props.link} target='_blank'>
                <div className='contact-icon'
                    style={{backgroundImage: `url(/assets/${props.id}.png)`}}>
                </div>
                {props.text}
            </a>
        </div>
    )
}

const LinksOfInterest = props => {
    return (
        <div className='footer-col'>
            <h2>Links of Interest</h2>
            <p>For the moment I haven't decided which are my links of interest...<br />
                Check out for updates soon!
            </p>
        </div>
    )
}

const SiteMap = props => {
    return (
        <div className='footer-col'>
            <h2>Site Map</h2>
            <div className='site-map'>
                <SiteMapItem tag='/' text='Home' />
                <SiteMapItem subitem tag='/#projects' text='Projects' />
                <SiteMapItem subitem tag='/#about-me' text='About Me' />
                <SiteMapItem subitem tag='/#technologies' text='Technologies' />
                <SiteMapItem subitem tag='/#areas-interest' text='Areas of Interest' />
                <SiteMapItem tag='/randomize-me' text='RandomizeMe' />
                <SiteMapItem subitem tag='/randomize-me/privacy-policy' text='Privacy Policy' />
            </div>
        </div>
    )
}

const SiteMapItem = props => {
    var classes = ['site-map-item'];
    if (props.subitem) {
        classes.push('subitem');
    }

    return (
        <div className={classes.join(' ')}>
            <Link to={props.tag}>
                {props.text}
            </Link>
        </div>
    )
}