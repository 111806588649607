// Header Component

import React, { Component } from 'react';
import './styles.scss';

export default class Header extends Component {
    render() {
        return (
            <div id='header'>
                <div className='header-content'>
                    <div id='hello-internet'>
                        "Hello, Internet!"
                    </div>
                    <div className='coder'>
                        <div></div>
                    </div>
                    <div className='description'>
                        I am a software engineer &amp; developer<br />
                        from Mexico City.
                    </div>
                </div>
            </div>
        )
    }
}