import React, { Component } from 'react';
import './styles.scss';
import ViewTemplate from '../../components/ViewTemplate';
import Footer from '../../components/Footer';

const InfoCollectionAndUse = props => {
    return (
        <div>
            <h2>Information Collection and Use</h2>
            <p>
                For a better experience, while using our Service, I may require you to provide us with certain personally identifiable information. The information that I request will be retained on your device and is not collected by me in any way.
            </p>
            <p>
                The app does not use third party services that may collect information used to identify you.
            </p>
        </div>
    )
}

const LogData = props => {
    return (
        <div>
            <h2>Log Data</h2>
            <p>
                I want to inform you that whenever you use my Service, in a case of an error in the app I collect data and information (through third party products) on your phone called Log Data. This Log Data may include information such as your device Internet Protocol (“IP”) address, device name, operating system version, the configuration of the app when utilizing my Service, the time and date of your use of the Service, and other statistics.
            </p>
        </div>
    )
}

const Cookies = props => {
    return (
        <div>
            <h2>Cookies</h2>
            <p>
                Cookies are files with a small amount of data that are commonly used as anonymous unique identifiers. These are sent to your browser from the websites that you visit and are stored on your device's internal memory.
            </p>
            <p>
                This Service does not use these “cookies” explicitly. However, the app may use third party code and libraries that use “cookies” to collect information and improve their services. You have the option to either accept or refuse these cookies and know when a cookie is being sent to your device. If you choose to refuse our cookies, you may not be able to use some portions of this Service.
            </p>
        </div>
    )
}

const Security = props => {
    return (
        <div>
            <h2>Security</h2>
            <p>
                I value your trust in providing us your Personal Information, thus we are striving to use commercially acceptable means of protecting it. But remember that no method of transmission over the internet, or method of electronic storage is 100% secure and reliable, and I cannot guarantee its absolute security.
            </p>
        </div>
    )
}

const LinksToOtherSites = props => {
    return (
        <div>
            <h2>Links to Other Sites</h2>
            <p>
                This Service may contain links to other sites. If you click on a third-party link, you will be directed to that site. Note that not all of these external sites are operated by me. Therefore, I strongly advise you to review the Privacy Policy of these websites. I have no control over and assume no responsibility for the content, privacy policies, or practices of any third-party sites or services not managed by me.
            </p>
        </div>
    )
}

const ChildrensPrivacy = props => {
    return (
        <div>
            <h2>Children's Privacy</h2>
            <p>
                These Services do not address anyone under the age of 13. I do not knowingly collect personally identifiable information from children under 13. In the case I discover that a child under 13 has provided me with personal information, I immediately delete this from our servers. If you are a parent or guardian and you are aware that your child has provided us with personal information, please contact me so that I will be able to do necessary actions.
            </p>
        </div>
    )
}

const ChangesToPrivacyPolicy = props => {
    return (
        <div>
            <h2>Changes to This Privacy Policy</h2>
            <p>
                I may update our Privacy Policy from time to time. Thus, you are advised to review this page periodically for any changes. I will notify you of any changes by posting the new Privacy Policy on this page. These changes are effective immediately after they are posted on this page.
            </p>
        </div>
    )
}

const ContactUs = props => {
    return (
        <div>
            <h2>Contact Us</h2>
            <p>
                If you have any questions or suggestions about my Privacy Policy, do not hesitate to contact me at martin@mfprint.io.
            </p>
        </div>
    )
}

class RandomizeMePrivacyPolicyView extends Component {
    render = () => {
        return (
            <ViewTemplate id='randomize-me-privacy-policy-view'>
                <div className='content'>
                    <h1>Randomize Me: Privacy Policy</h1>
                    <p>Martín Figueroa Padilla built the RandomizeMe app as a Free app. This SERVICE is provided by Martín Figueroa Padilla at no cost and is intended for use as is.</p>
                    <InfoCollectionAndUse />
                    <LogData />
                    <Cookies />
                    <Security />
                    <LinksToOtherSites />
                    <ChildrensPrivacy />
                    <ChangesToPrivacyPolicy />
                    <ContactUs />
                </div>
                <Footer />
            </ViewTemplate>
        )
    }
}

export default RandomizeMePrivacyPolicyView;