import React, { Component } from 'react';
import './App.scss';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import HomeView from './views/HomeView';
import RandomizeMePrivacyPolicyView from './views/RandomizeMePrivacyPolicyView';
import Error404View from './views/Error404View';
import RandomizeMeView from './views/RandomizeMeView';
import ReactGA from 'react-ga';

ReactGA.initialize('UA-172908819-1');

class App extends Component {
    componentDidMount = () => {
        ReactGA.pageview(this.props.location.pathname + this.props.location.search);
        ReactGA.send('pageview');
    }

    componentDidUpdate = (prevProps) => {
        console.log(this.props.location);
        if (this.props.location !== prevProps.location) {
            ReactGA.pageview(this.props.location.pathname + this.props.location.search);
            ReactGA.send('pageview');
        }
    }

    render = () => {
        return (
            <div>
                <Route exact path='/' component={HomeView} />
                <Route exact path='/randomize-me' component={RandomizeMeView} />
                <Route exact path='/randomize-me/privacy-policy' component={RandomizeMePrivacyPolicyView} />
                <Route component={Error404View} />
            </div>
        )
    }
}

class _App extends Component {
    render = () => {
        return (
            <Router>
                <Route component={App} />
            </Router>
        )
    }
}

export default _App;