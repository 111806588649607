// Projects Component

import React, { Component } from 'react';
import './styles.scss';

import { Link } from 'react-router-dom';

export default class Projects extends Component {
    constructor(props) {
        super(props);

        this.projects = [
            {
                id: 'randomize-project',
                title: 'RandomizeMe',
                type: 'mobile',
                description: [
                    `Mobile application for selecting random numbers in a range, elements from a list, or generating random teams of a given size.`,
                        <br />, <Link to='/randomize-me'>Learn more...</Link>
                ],
                color: '#3074e3'
            },
            {
                id: 'getter-project',
                title: 'Get the Getter',
                type: 'webapp',
                description: [
                    `Web application for generating getters and setters by providing Java private and protected variables declaration code.`,
                        // <br />, <a href='/get-the-getter/'>Go to Application</a>
                ],
                color: '#99686f'
            },
            {
                id: 'chess-project',
                title: 'Chess System',
                type: 'webapp',
                description: `Web application for managing a chess class tournament, assistance, extra points, and more.
                    Currently managed by me.`,
                color: '#e3bf30'
            },
            {
                id: 'fitness-project',
                title: 'Zona Fitness Information System',
                type: 'webapp',
                description: `A management system for Zona Fitness Club sports center. Currently managed by a third-party.`,
                color: '#e37e30'
            },
            {
                id: 'izcaltia-project',
                title: 'Izcaltia Website',
                type: 'website',
                description: [
                    'Website for Asesoría Izcaltia in Mexico City. Currently managed by me.',
                    <br />, 'Live at ', <a href='http://izcaltia.mx' target='_blank'>www.izcaltia.mx</a>
                ],
                color: '#30cee3'
            },
            {
                id: 'bullying-project',
                title: 'Bullying en México Website',
                type: 'website',
                description: [
                    'Website for Bullying en México in Mexico City. Currently managed by me.',
                    <br />, 'Live at ', <a href='http://bullyingenmexico.com' target='_blank'>www.bullyingenmexico.com</a>
                ],
                color: '#e33030'
            },
            {
                id: 'ballet-project',
                title: 'Academia de Ballet Assoluta Website',
                type: 'website',
                description: [
                    'Website for Academia de Ballet Assoluta. Currently managed by a third party so the live version might not be 100% my work.',
                    <br />, 'Live at ', <a href='http://academiadeballet.com.mx' target='_blank'>www.academiadeballet.com.mx</a>
                ],
                color: '#e3306c'
            }
        ];
    }

    render() {
        return (
            <div id='projects' className='section'>
                <h1>Projects</h1>
                <div className='projects-list'>
                    {this.projects.map((project) => {
                        return <Project title={project.title} key={project.id} color={project.color}
                            id={project.id} type={project.type} description={project.description} />
                    })}
                </div>
            </div>
        )
    }
}

const Project = props => {
    var type = props.type === 'mobile' ? 'Mobile Application'
        : props.type === 'webapp' ? 'Web Application'
        : props.type === 'website' ? 'Website'
        : 'Desktop Application';

    var color = props.color || 'grey';
    // var description = props.description.map((item, i) => {

    // });

    return (
        <div className='project-box'>
            <div className='project'>
                <div className='project-header'
                    style={{
                        backgroundImage: 'url(/assets/' + props.id + '.png)',
                        backgroundColor: color
                    }}
                >
                </div>
                <div className='project-body' style={{borderColor: color}}>
                    <div className='project-title'>{props.title}</div>
                    <div className='project-type'>{type}</div>
                    <div className='project-description'>
                        {props.description}
                    </div>
                </div>
            </div>
        </div>
    )
}