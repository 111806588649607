// AboutMe Component

import React, { Component } from 'react';
import './styles.scss';

export default class AboutMe extends Component {
    render() {
        return (
            <div id='about-me' className='section'>
                <h1>About Me</h1>
                <div className='profile'>
                    <div className='profile-img'></div>
                    <div className='profile-info'>
                        <div className='profile-name'>My name is Martín Figueroa Padilla.</div>
                        <div className='profile-where'>I live in Mexico City, Mexico.</div>
                    </div>
                </div>
                <div className='columns'>
                    <div className='column'>
                        <div className='column-content'>
                            <h2>Studies</h2>
                            <p>
                                I studied Computer Science at Tecnológico de Monterrey, Campus Ciudad de México in Mexico, and graduated in December 2019.
                            </p>
                        </div>
                    </div>
                    <div className='column'>
                        <div className='column-content'>
                            <h2>Hobbies</h2>
                            <p>
                                My hobbies include watching movies & series on Netflix, singing, playing the piano, writing songs, essays, and stories, reading, playing video games, amongst other things like coding and procrastination.
                            </p>
                        </div>
                    </div>
                    <div className='column'>
                        <div className='column-content'>
                            <h2>Other Stuff</h2>
                            <p>
                                My level of English is C1 (Cambridge Certificate in Advanced English). I have a basic knowledge of French.<br />
                                I believe in human kindness.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}