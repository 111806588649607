import React, { Component } from 'react';
import './styles.scss';
import ViewTemplate from '../../components/ViewTemplate';

import Header from '../../components/Header';
import Projects from '../../components/Projects';
import Footer from '../../components/Footer';
import Technologies from '../../components/Technologies';
import AboutMe from '../../components/AboutMe';
import AreasOfInterest from '../../components/AreasOfInterest';

class HomeView extends Component {
    render = () => {
        return (
            <ViewTemplate id='home-view'>
                <Header />
                <Projects />
                <AboutMe />
                <Technologies />
                <AreasOfInterest />
                <Footer />
            </ViewTemplate>
        )
    }
}

export default HomeView;