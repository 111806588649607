// Technologies Component

import React, { Component } from 'react';
import './styles.scss';

export default class Technologies extends Component {
    constructor(props) {
        super(props);

        this.technologies = {
            'csharp': 'C#',
            'nodejs': 'Node.js',
            'html': 'HTML5',
            'css': 'CSS3 + Sass/SCSS',
            'js': 'JavaScript + jQuery',
            'java': 'Java + Servlets',
            'php': 'PHP',
            'sql': 'SQL (MySQL, SQLServer, Sqlite3)',
            'angular': 'Angular',
            'react': 'React + React Native',
            'cordova': 'Cordova',
            'python': 'Python',
            'swift': 'Swift',
            'cpp': 'C++'
        };

        // var sorted = {};
        // Object.keys(this.technologies).sort().forEach(key => {
        //     sorted[key] = this.technologies[key];
        // });

        // this.technologies = sorted;
    }

    render() {
        var technologiesRender = [];
        for (const technology in this.technologies) {
            technologiesRender.push(
                <Technology name={this.technologies[technology]}
                    icon={technology} key={technology} />
            );
        }

        return (
            <div id='technologies' className='section'>
                <h1>Technologies</h1>
                <h2>{this.more}</h2>
                <div className='technologies-list'>
                    {technologiesRender}
                </div>
            </div>
        )
    }
}

const Technology = props => {
    return (
        <div className='technology-box'>
            <div className='technology'>
                <div className={`icon ${props.icon}`}
                    style={{backgroundImage: `url(/assets/${props.icon}.png)`}}>
                </div>
                {props.name}
            </div>
        </div>
    )
}