// AreasOfInterest Component

import React, { Component } from 'react';
import './styles.scss';

export default class AreasOfInterest extends Component {
    render() {
        return (
            <div id='areas-interest' className='section'>
                <h1>Areas of Interest</h1>
                <div className='areas-interest-list'>
                    <Area icon='ui' name='User Experience (UX)' />
                    <Area icon='engineering' name='Software Engineering' />
                    <Area icon='ai' name='Artificial Intelligence' />
                    <Area icon='fullstack' name={['Fullstack Development', <br/>,'(Applications + Services)']} />
                </div>
            </div>
        )
    }
}

const Area = (props) => {
    return (
        <div className='area-interest-box'>
            <div className='area-interest'>
                <div className='area-icon'
                    style={{backgroundImage: `url(/assets/${props.icon}.png)`}}>
                </div>
                {props.name}
            </div>
        </div>
    )
}